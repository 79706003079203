import { Component, Input } from '@angular/core';
type ITextTag = 'h1' | 'h2' | 'h5' | 'p' | 'small';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
})
export class TextComponent {
  @Input() tag: ITextTag = 'p';
  @Input() weight?: string;
}
