import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input({ required: true }) size: 'small' | 'medium' | 'large' | 'icon' =
    'medium';
  @Input({ required: true }) color:
    | 'primary'
    | 'secondary'
    | 'primary-outlined'
    | 'secondary-outlined'
    | 'secondary-outlined-white'
    | 'elevated'
    | 'black' = 'primary';
  @Input() disabled: boolean = false;
  @Input() isIcon: boolean = false;
  @Input() type: 'button' | 'submit' = 'button';
  @Input() minWidth: string = '';
  @Input() minHeight: string = '';
  @Input() elevation: 'gk-elevation-1' | '' = '';
  @Output() clicked: EventEmitter<void> = new EventEmitter();
  @Input() active?: boolean;
}
