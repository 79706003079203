<ng-template #projected>
  <ng-content></ng-content>
</ng-template>

@if (isIcon) {
  <button
    (click)="clicked.emit()"
    mat-icon-button
    [ngClass]="[
      'button',
      'button-' + color,
    ]"
    [disabled]="disabled"
    [style.minWidth]="minWidth"
    [style.minHeight]="minHeight"
    [type]="type"
  >
    <ng-container *ngTemplateOutlet="projected"></ng-container>
  </button>
} @else {
  <button
    (click)="clicked.emit()"
    mat-button
    [ngClass]="[
      'button',
      'button-' + size,
      'button-' + color,
      active ? 'button-active' : '',
    ]"
    [disabled]="disabled"
    [style.minWidth]="minWidth"
    [style.minHeight]="minHeight"
    [type]="type"
  >
    <ng-container *ngTemplateOutlet="projected"></ng-container>
  </button>
}
