<ng-template #projected>
  <ng-content></ng-content>
</ng-template>

<div *ngIf="tag === 'h1'">
  <h1 [style.fontWeight]="weight">
    <ng-container *ngTemplateOutlet="projected"></ng-container>
  </h1>
</div>

<div *ngIf="tag === 'h2'">
  <h2 [style.fontWeight]="weight">
    <ng-container *ngTemplateOutlet="projected"></ng-container>
  </h2>
</div>

<div *ngIf="tag === 'h5'">
  <h5 [style.fontWeight]="weight">
    <ng-container *ngTemplateOutlet="projected"></ng-container>
  </h5>
</div>

<div *ngIf="tag === 'p'">
  <p [style.fontWeight]="weight">
    <ng-container *ngTemplateOutlet="projected"></ng-container>
  </p>
</div>

<div *ngIf="tag === 'small'">
  <small [style.fontWeight]="weight"
    ><ng-container *ngTemplateOutlet="projected"></ng-container
  ></small>
</div>
